const Page = () => {
  return (
    <div>
      <h1>Temporary unavailable</h1>
      <p>
        Sorry, this page is temporarily unavailable. Please try again later.
      </p>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Page.getLayout = (page: any) => <main>{page}</main>;

export default Page;
